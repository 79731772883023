import { TokenAmount, Pair, Currency } from '../dist'
import { useEffect, useMemo, useState } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useV1FactoryContract } from '../hooks/useContract'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairsNew(liquidityTokensWithBalances: any): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const pairAddresses = useMemo(
    () =>
      liquidityTokensWithBalances.map(
        (liquidityTokenWithBalance: { tokens: [Currency, Currency]; liquidityToken: { address: any } }) => {
          const [token0, token1] = liquidityTokenWithBalance?.tokens
          const tokenA = wrappedCurrency(token0, chainId)
          const tokenB = wrappedCurrency(token1, chainId)
          return tokenA && tokenB && !tokenA.equals(tokenB)
            ? liquidityTokenWithBalance.liquidityToken.address
            : undefined
        }
      ),
    [chainId, liquidityTokensWithBalances]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokentemp0 = liquidityTokensWithBalances[i].tokens[0]
      const tokenTemp1 = liquidityTokensWithBalances[i].tokens[1]

      const tokenA = wrappedCurrency(tokentemp0, chainId)
      const tokenB = wrappedCurrency(tokenTemp1, chainId)

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [chainId, liquidityTokensWithBalances, results])
}

export function usePairs(
  currencies: [Currency | undefined, Currency | undefined][],
  pairAddress?: string
): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )
  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB)
          ? !!pairAddress
            ? pairAddress
            : Pair.getAddress(tokenA, tokenB)
          : undefined
      }),
    [tokens]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}
export function usePair(tokenA?: Currency, tokenB?: Currency, pairAddress?: string): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]], pairAddress)[0]
}
export function useRemovePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()
  const factory = useV1FactoryContract()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const [pairAddresses, setPairAddresses] = useState<(string | undefined)[]>([])

  useEffect(() => {
    async function fetchPairAddresses() {
      const addresses = await Promise.all(
        tokens.map(async ([tokenA, tokenB]) => {
          if (tokenA && tokenB && !tokenA.equals(tokenB)) {
            return await factory?.getPair(tokenA.address, tokenB.address)
          }
          return undefined
        })
      )
      setPairAddresses(addresses)
    }

    fetchPairAddresses()
  }, [tokens, factory])

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}
export function useRemovePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return useRemovePairs([[tokenA, tokenB]])[0]
}
