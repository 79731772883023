import { Currency, CurrencyAmount, currencyEquals, ETHER, Token } from '../../../src/dist'
import React, { CSSProperties, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import {
  toV2LiquidityToken,
  useAddUserToken,
  useRemoveUserAddedToken,
  useTrackedTokenPairs
} from '../../state/user/hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import Column from '../Column'
import { RowFixed } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { MouseoverTooltip } from '../Tooltip'
import { FadedSpan, MenuItem } from './styleds'
import Loader from '../Loader'
import { useV1FactoryContract } from '../../hooks/useContract'
import { LinkStyledButton, TYPE } from '../../theme'

function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : currency === ETHER ? 'ETHER' : ''
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`
const Tag = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  border-radius: 4px;
  padding: 0.25rem 0.3rem 0.25rem 0.3rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: flex-end;
  margin-right: 4px;
`

function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

const TagContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

function TokenTags({ currency }: { currency: Currency }) {
  if (!(currency instanceof WrappedTokenInfo)) {
    return <span />
  }

  const tags = currency.tags
  if (!tags || tags.length === 0) return <span />

  const tag = tags[0]

  return (
    <TagContainer>
      <MouseoverTooltip text={tag.description}>
        <Tag key={tag.id}>{tag.name}</Tag>
      </MouseoverTooltip>
      {tags.length > 1 ? (
        <MouseoverTooltip
          text={tags
            .slice(1)
            .map(({ name, description }) => `${name}: ${description}`)
            .join('; \n')}
        >
          <Tag>...</Tag>
        </MouseoverTooltip>
      ) : null}
    </TagContainer>
  )
}

function CurrencyRow({
  currency,
  onSelect,
  isSelected,
  otherSelected,
  style
}: {
  currency: Currency
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
}) {
  const factory = useV1FactoryContract()
  const [pairAddresses, setPairAddresses] = useState<string[]>([])
  const { account, chainId } = useActiveWeb3React()
  const key = currencyKey(currency)
  const balance = useCurrencyBalance(account ?? undefined, currency)
  const addToken = useAddUserToken()
  const removeToken = useRemoveUserAddedToken()
  const trackedTokenPairs = useTrackedTokenPairs()
  useEffect(() => {
    const fetchPairAddresses = async () => {
      const addresses = await Promise.all(
        trackedTokenPairs.map(async ([tokenA, tokenB]) => {
          const pairAddress = await factory?.getPair(tokenA.address, tokenB.address)
          return pairAddress
        })
      )
      setPairAddresses(addresses)
    }

    fetchPairAddresses()
  }, [factory, trackedTokenPairs])
  const tokenPairsWithLiquidityTokens = useMemo(() => {
    return trackedTokenPairs.map((tokens, index) => {
      const pairAddress = pairAddresses[index]
      return { liquidityToken: toV2LiquidityToken(tokens, pairAddress), tokens }
    })
  }, [trackedTokenPairs, pairAddresses])
  const liquidityPair = tokenPairsWithLiquidityTokens.filter(
    item => item.liquidityToken.address !== '0x0000000000000000000000000000000000000000'
  )
  const liquidityTokens = liquidityPair.map(item => item.tokens).flat()
  const matchToken = liquidityTokens.find(item => item.symbol === currency.symbol)
  const selectAndDispatch = async () => {
    if (currency instanceof Token) await addToken(currency)
    onSelect()
  }
  return (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      onClick={() => (isSelected ? null : selectAndDispatch())}
      disabled={isSelected}
      selected={otherSelected}
    >
      <CurrencyLogo currency={currency} size={'24px'} />
      <Column>
        <Text title={currency.name} fontWeight={500}>
          {currency.symbol}
        </Text>
        {currency.symbol !== 'PEN' &&
          currency.symbol !== 'CPEN' &&
          currency.symbol !== 'FIVE' &&
          currency.symbol !== 'BARK' &&
          !matchToken && (
            <FadedSpan>
              <TYPE.main fontWeight={500}>
                Added by user
                <LinkStyledButton
                  onClick={event => {
                    event.stopPropagation()
                    if (chainId && currency instanceof Token) {
                      removeToken(chainId, currency.address)
                    }
                  }}
                >
                  (Remove)
                </LinkStyledButton>
              </TYPE.main>
            </FadedSpan>
          )}
      </Column>
      <TokenTags currency={currency} />
      <RowFixed style={{ justifySelf: 'flex-end' }}>
        {balance ? <Balance balance={balance} /> : account ? <Loader /> : null}
      </RowFixed>
    </MenuItem>
  )
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showETH
}: {
  height: number
  currencies: Currency[]
  selectedCurrency: Currency | undefined
  onCurrencySelect: (currency: Currency) => void
  otherCurrency: Currency | undefined
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showETH: boolean
}) {
  const itemData = useMemo(() => (showETH ? [Currency.ETHER, ...currencies] : currencies), [currencies, showETH])
  const Row = useCallback(
    ({ data, index, style }) => {
      const currency: Currency = data[index]
      const isSelected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, currency))
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))
      const handleSelect = () => onCurrencySelect(currency)
      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      )
    },
    [onCurrencySelect, otherCurrency, selectedCurrency]
  )

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[index]), [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={itemData}
      itemCount={itemData.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
